import axios from 'axios';

// ENV variables
const appVersion = process.env.VUE_APP_UPDATE_VERSION;
const appPlatform = process.env.VUE_APP_PLATFORM;

// Intercepteur de requêtes
axios.interceptors.request.use(
  function (config) {
    const location = JSON.parse(window.localStorage.getItem('oz'))
    if (location) config.headers.Authorization = `Bearer ${location.token}`
    return config
  },

  function (error) {
    return Promise.reject(error)
  }
)

// Intercepteur de réponses
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json', 
  'X-app-version' : appVersion,
  'X-app-platform' : appPlatform
}
const timeout = 7200000;
export default class AxiosFunction {
  static axiosGet (url, data = null) {
    
    return data
      ? axios.get(url, {
          params: {
          ...data
          },
          headers
        })
      : axios.get(url, { headers})
  }

  static axiosPost (options) {
    if (options.formData) {
      const params = { 'Content-Type': 'multipart/form-data', 'X-app-version' : appVersion, 'X-app-platform' : appPlatform }
      return axios.post(options.url, options.data, { headers: params, timeout,
        onUploadProgress: function(progressEvent) {
          var axios_progressbar_film = document.querySelector("#axios_progressbar_film");
          var gauge = document.querySelector("#axios_progressbar_film .progress-bar");
          var percentCompleted = Math.round((progressEvent.loaded / progressEvent.total)*100);
          if(axios_progressbar_film != null){
            axios_progressbar_film.setAttribute('aria-valuenow', percentCompleted);
          }
          if(gauge != null){
            var value = `${percentCompleted}%`;
            gauge.innerHTML = value;
            gauge.style = `width: ${value};`;
            if (percentCompleted === 100) {
              gauge.textContent = `Envoi terminé ! (Traitement en cours...)`;
            }
          }
        } })
    }
    return axios.post(options.url, options.data, { headers, timeout })
  }

  static axiosPut (options) {
    if (options.formData) {
      const params = { 'Content-Type': 'multipart/form-data', 'X-app-version' : appVersion, 'X-app-platform' : appPlatform }
      return axios.put(options.url, options.data, { headers: params, timeout,
        onUploadProgress: function(progressEvent) {
          var axios_progressbar_film = document.querySelector("#axios_progressbar_film");
          var gauge = document.querySelector("#axios_progressbar_film .progress-bar");
          var percentCompleted = Math.round((progressEvent.loaded / progressEvent.total)*100);
          if(axios_progressbar_film != null){
            axios_progressbar_film.setAttribute('aria-valuenow', percentCompleted);
          }
          if(gauge != null){
            var value = `${percentCompleted}%`;
            gauge.innerHTML = value;
            gauge.style = `width: ${value};`;
            if (percentCompleted === 100) {
              gauge.textContent = `Terminé!`;
            }
          }
        } })
    }
    return axios.put(options.url, options.data, { headers, timeout })
  }

  static axiosDelete (url, data) {
    return axios.delete(url, { data, headers })
  }

  static axiosPatch (options) {
    return axios.patch(options.url, options.data, { headers })
  }

  static responseToCallback (promise, succesCallback, errorCallback) {
    promise
      .then(response => {
        if (succesCallback) succesCallback(true, response.data) //returns success response data
      })
      .catch(({ response }) => {
        if (errorCallback) errorCallback(false, response.data) //returns error response data
      })
  }
}
